<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="complementoGlaucoma"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <span class="autoFillBtnLabel">Medicamentos</span>
            <div class="custom-input-group">
              <div class="custom-input h-38">
                <div class="custom-input eye-box-container radius-top-e h-38 min-h no-bb with-br">
                  <span>Medicamentos</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>1º Mês</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>2º Mês</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>3º Mês</span>
                </div>
                <div class="custom-input eye-box-container h-38 no-bb with-br">
                  <span>Posologia</span>
                </div>
                <div class="custom-input eye-box-container radius-top-d h-38 no-bb min-h-o">
                  <span>Olho(s)</span>
                </div>
              </div>
              <div class="custom-input min-h">
                <div class="text-area type-2 with-br min-h" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.medicamentoOpcao1"
                    id="medicamentoOpcao1"
                    :disabled="!canEdit"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    track-by="medicine_name"
                    class="mb-0"
                    @select="onSelect($event, 'medicamentoOpcao1')"
                  >
                    <template slot="caret">
                      <div class="chevron" >
                        <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                    <template slot="option" slot-scope="{option}"> {{option.medicine_name}} </template>
                  </multiselect>
                  <Close v-if="form.fields.medicamentoOpcao1 && canEdit" class="icon close" @click="clearFieldsMedicamento('medicamentoOpcao1')" />
                </div>
                <div class="text-area type-2 no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.primeiroMesOpcao1"
                    :readonly="!canEdit"
                    @input="el => updateForm('primeiroMesOpcao1', el)"
                    @blur="updateMedicalRecord(form)"
                    id="primeiro-mes-opcao1"
                    type="text"
                    class="form-control"
                    ref="primeiroMesOpcao1"
                  />
                </div>
                <div class="text-area type-2 no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.segundoMesOpcao1"
                    :readonly="!canEdit"
                    @input="el => updateForm('segundoMesOpcao1', el)"
                    @blur="updateMedicalRecord(form)"
                    id="segundo-mes-opcao1"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.terceiroMesOpcao1"
                    :readonly="!canEdit"
                    @input="el => updateForm('terceiroMesOpcao1', el)"
                    @blur="updateMedicalRecord(form)"
                    id="terceiro-mes-opcao1"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.posologiaOpcao1"
                    :readonly="!canEdit"
                    @input="el => updateForm('posologiaOpcao1', el)"
                    @blur="updateMedicalRecord(form)"
                    id="posologia-opcao1"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 no-bb min-h-o" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.olhoOpcao1"
                    id="olhoOpcao1"
                    :disabled="!canEdit"
                    :options="['OE', 'OD', 'AO']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect()"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                    <Close v-if="form.fields.olhoOpcao1 && canEdit" class="icon close" @click="clearFieldsOlho('olhoOpcao1')" />
                </div>
              </div>
              <div class="custom-input no-bb min-h">
                  <div class="text-area type-2 borderB with-br min-h" :class="{'check-disable': !canEdit}">
                    <multiselect
                      v-model="form.fields.medicamentoOpcao2"
                      id="medicamentoOpcao2"
                      :disabled="!canEdit"
                      :options="options"
                      :option-height="40"
                      :showLabels="false"
                      :searchable="false"
                      :allowEmpty="false"
                      trackBy="medicine_name"
                      placeholder="Selecionar"
                      class="mb-0"
                      @select="onSelect($event, 'medicamentoOpcao2')"
                    >
                      <template slot="caret">
                        <div class="chevron">
                           <ChevronDown v-if="canEdit"></ChevronDown>
                        </div>
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                      <template slot="option" slot-scope="{option}"> {{option.medicine_name}} </template>
                    </multiselect>
                    <Close v-if="form.fields.medicamentoOpcao2 && canEdit" class="icon close" @click="clearFieldsMedicamento('medicamentoOpcao2')" />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.primeiroMesOpcao2"
                    :readonly="!canEdit"
                    @input="el => updateForm('primeiroMesOpcao2', el)"
                    @blur="updateMedicalRecord(form)"
                    id="primeiro-mes-opcao2"
                    type="text"
                    class="form-control"
                    ref="primeiroMesOpcao2"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.segundoMesOpcao2"
                    :readonly="!canEdit"
                    @input="el => updateForm('segundoMesOpcao2', el)"
                    @blur="updateMedicalRecord(form)"
                    id="segundo-mes-opcao2"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.terceiroMesOpcao2"
                    :readonly="!canEdit"
                    @input="el => updateForm('terceiroMesOpcao2', el)"
                    @blur="updateMedicalRecord(form)"
                    id="terceiro-mes-opcao2"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.posologiaOpcao2"
                    :readonly="!canEdit"
                    @input="el => updateForm('posologiaOpcao2', el)"
                    @blur="updateMedicalRecord(form)"
                    id="posologia-opcao2"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 no-bb borderB min-h-o" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.olhoOpcao2"
                    id="olhoOpcao2"
                    :disabled="!canEdit"
                    :options="['OE', 'OD', 'AO']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect()"
                  >
                    <template slot="caret">
                      <div class="chevron">
                         <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <Close v-if="form.fields.olhoOpcao2 && canEdit" class="icon close" @click="clearFieldsOlho('olhoOpcao2')" />
                </div>
              </div>
              <div class="custom-input no-bb min-h">
                <div class="text-area type-2 borderB with-br min-h" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.medicamentoOpcao3"
                    id="medicamentoOpcao3"
                    :disabled="!canEdit"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    trackBy="medicine_name"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect($event, 'medicamentoOpcao3')"
                  >
                    <template slot="caret">
                      <div class="chevron">
                         <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                    <template slot="option" slot-scope="{option}"> {{option.medicine_name}} </template>
                  </multiselect>
                  <Close v-if="form.fields.medicamentoOpcao3 && canEdit" class="icon close" @click="clearFieldsMedicamento('medicamentoOpcao3')" />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.primeiroMesOpcao3"
                    :readonly="!canEdit"
                    @input="el => updateForm('primeiroMesOpcao3', el)"
                    @blur="updateMedicalRecord(form)"
                    id="primeiro-mes-opcao3"
                    type="text"
                    class="form-control"
                    ref="primeiroMesOpcao3"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.segundoMesOpcao3"
                    :readonly="!canEdit"
                    @input="el => updateForm('segundoMesOpcao3', el)"
                    @blur="updateMedicalRecord(form)"
                    id="segundo-mes-opcao3"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.terceiroMesOpcao3"
                    :readonly="!canEdit"
                    @input="el => updateForm('terceiroMesOpcao3', el)"
                    @blur="updateMedicalRecord(form)"
                    id="terceiro-mes-opcao3"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.posologiaOpcao3"
                    :readonly="!canEdit"
                    @input="el => updateForm('posologiaOpcao3', el)"
                    @blur="updateMedicalRecord(form)"
                    id="posologia-opcao3"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 borderB min-h-o" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.olhoOpcao3"
                    id="olhoOpcao3"
                    :disabled="!canEdit"
                    :options="['OE', 'OD', 'AO']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect()"
                  >
                    <template slot="caret">
                      <div class="chevron">
                         <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <Close v-if="form.fields.olhoOpcao3 && canEdit" class="icon close" @click="clearFieldsOlho('olhoOpcao3')" />
                </div>
              </div>
              <div class="custom-input no-bb min-h">
                <div class="text-area type-2 borderB with-br min-h" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.medicamentoOpcao4"
                    id="medicamentoOpcao4"
                    :disabled="!canEdit"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    trackBy="medicine_name"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect($event, 'medicamentoOpcao4')"
                  >
                    <template slot="caret">
                      <div class="chevron">
                         <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                    <template slot="option" slot-scope="{option}"> {{option.medicine_name}} </template>
                  </multiselect>
                  <Close v-if="form.fields.medicamentoOpcao4 && canEdit" class="icon close" @click="clearFieldsMedicamento('medicamentoOpcao4')" />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.primeiroMesOpcao4"
                    :readonly="!canEdit"
                    @input="el => updateForm('primeiroMesOpcao4', el)"
                    @blur="updateMedicalRecord(form)"
                    id="primeiro-mes-opcao4"
                    type="text"
                    class="form-control"
                    ref="primeiroMesOpcao4"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.segundoMesOpcao4"
                    :readonly="!canEdit"
                    @input="el => updateForm('segundoMesOpcao4', el)"
                    @blur="updateMedicalRecord(form)"
                    id="segundo-mes-opcao4"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.terceiroMesOpcao4"
                    :readonly="!canEdit"
                    @input="el => updateForm('terceiroMesOpcao4', el)"
                    @blur="updateMedicalRecord(form)"
                    id="terceiro-mes-opcao4"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="custom-input no-bb with-br">
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.posologiaOpcao4"
                    :readonly="!canEdit"
                    @input="el => updateForm('posologiaOpcao4', el)"
                    @blur="updateMedicalRecord(form)"
                    id="posologia-opcao1"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="text-area type-2 borderB min-h-o" :class="{'check-disable': !canEdit}">
                  <multiselect
                    v-model="form.fields.olhoOpcao4"
                    id="olhoOpcao4"
                    :disabled="!canEdit"
                    :options="['OE', 'OD', 'AO']"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="mb-0"
                    @select="onSelect()"
                  >
                    <template slot="caret">
                      <div class="chevron">
                         <ChevronDown v-if="canEdit"></ChevronDown>
                      </div>
                    </template>
                    <template slot="noOptions"> Nenhuma opção </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <Close v-if="form.fields.olhoOpcao4 && canEdit" class="icon close" @click="clearFieldsOlho('olhoOpcao4')" />
                </div>
              </div>
            </div>
            <div class="custom-input">
                <div>
                  <span class="autoFillBtnLabel">Anamnese</span> 
                </div>
                <div>
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.anamnese"
                    :readonly="!canEdit"
                    @input="el => updateForm('anamnese', el)"
                    @blur="updateMedicalRecord(form)"
                    id="anamnese"
                    type="text"
                    class="form-control"
                    ref="anamnese"
                    placeholder="Descrever"
                    :disabled="!canEdit"
                  />
                </div>
              </div>
            <div class="laterality-container">
              <span class="autoFillBtnLabel">Paciente realizou tratamento prévio ou está em tratamento da doença</span>
              <div class="laterality-content">
                <div class="laterality-input">  
                  <div class="laterality-option">
                    <input
                      :disabled="!canEdit"
                      type="radio"
                      name="tratamento"
                      value="true"
                      v-model="form.fields.tratamentoSim"
                      @input="toggleTratamento('tratamentoSim')"
                    />Sim
                  </div>
                  <div class="laterality-option">
                    <input
                      :disabled="!canEdit"
                      type="radio"
                      name="tratamento"
                      value="false"
                      v-model="form.fields.tratamentoNao"
                      @input="toggleTratamento('tratamentoNao')"
                    />Não
                  </div>
                </div>  
              </div>
              <div class="custom-input">
                <div>
                  <span class="autoFillBtnLabel">Relatar</span> 
                </div>
                <div>
                  <TextArea
                    autocomplete="off"
                    :value="form.fields.relatar"
                    :readonly="!canEdit"
                    @input="el => updateForm('relatar', el)"
                    @blur="updateMedicalRecord(form)"
                    id="relatar"
                    type="text"
                    class="form-control"
                    ref="relatar"
                    placeholder="Descrever"
                    :disabled="!canEdit || !form.fields.tratamentoSim"
                  />
                </div>
              </div>
            </div>
            <div class="laterality-container">
              <span class="autoFillBtnLabel">O paciente é considerado incapaz?</span>
              <div class="laterality-content">
                <div class="laterality-input"> 
                  <div class="laterality-option">
                    <input
                      :disabled="!canEdit"
                      type="radio"
                      name="incapaz"
                      value="true"
                      v-model="form.fields.incapazSim"
                      @input="toggleIncapaz('incapazSim')"
                    />Sim
                  </div>
                  <div class="laterality-option">
                    <input
                      :disabled="!canEdit"
                      type="radio"
                      name="incapaz"
                      value="false"
                      v-model="form.fields.incapazNao"
                      @input="toggleIncapaz('incapazNao')"
                    />Não
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  import { debounce } from 'lodash';
  import { getCurrentClinic } from '@/utils/localStorageManager'
  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      TextArea: () => import('@/components/General/TextArea'),
      Close: () => import('@/assets/icons/close.svg'),
      ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        form: state => state.attendance.form.complementoGlaucoma,
        medicalRecordsStore: state => state.attendance.medicalRecordsStore
      }),
    },

    async mounted() {
      this.debounceInput = debounce(this.onInput, 300)
      await this.getMedicamento()
    },
    data() {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        clinic: getCurrentClinic(),
        options: [
          "Brinzolamida",
          "Travaprost 0,004% 2,5ml",
          "Cloridrato de dorzolamida 2%",
          "Brimonidina tartarato 2 mg/ml",
          "Bimatoprost 0,03% 3ml",
          "Latanoprost 0,005% 2,5ml",
          "Timolol 0,5%"
        ],
      }
    },
    methods: {
      ...mapActions('attendance/form', ['updateMedicalRecord','destroyMedicalRecord']),
      ...mapActions('attendance/form/complementoGlaucoma', ['handleFields','handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
      },
      hideForm() {
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        })
      },
      async getMedicamento(){
        this.api.getComplementGlaucomaOptions().then(
          response => {
            this.options = response.data?.map(option => {
              return option
            })
          }
        ).catch(
          error => {
            console.log(error)
          }
        )
      }, 
      clearFieldsMedicamento(medicamento){
        if(medicamento === 'medicamentoOpcao1') {
          this.form.fields.medicamentoOpcao1 = null
          this.form.fields.posologiaOpcao1 = null
        }
        else if(medicamento === 'medicamentoOpcao2') {
          this.form.fields.medicamentoOpcao2 = null
          this.form.fields.posologiaOpcao2 = null
        } 
        else if(medicamento === 'medicamentoOpcao3') {
          this.form.fields.medicamentoOpcao3 = null
          this.form.fields.posologiaOpcao3 = null
        }
        else if(medicamento === 'medicamentoOpcao4') {
          this.form.fields.medicamentoOpcao4 = null
          this.form.fields.posologiaOpcao4 = null
        }
        this.updateForm()
        this.updateMedicalRecord(this.form)
      },
      clearFieldsOlho(olho){
        if(olho === 'olhoOpcao1') {
          this.form.fields.olhoOpcao1 = null
        }
        else if(olho === 'olhoOpcao2') {
          this.form.fields.olhoOpcao2 = null
        } 
        else if(olho === 'olhoOpcao3') {
          this.form.fields.olhoOpcao3 = null
        }
        else if(olho === 'olhoOpcao4') {
          this.form.fields.olhoOpcao4 = null
        }
        this.updateForm()
        this.updateMedicalRecord(this.form)
      },
      onInput(key, value) {
        this.handleFields({ key, value })
        this.updateMedicalRecord(this.form)
      },
      toggleTratamento(value) {
        this.form.fields.tratamentoSim = ''
        this.form.fields.tratamentoNao = ''
        if(value === 'tratamentoSim') {
          this.form.fields.tratamentoSim = true
        } else if (value === 'tratamentoNao') {
          this.form.fields.tratamentoNao = true
        }
        this.updateForm()
        this.updateMedicalRecord(this.form)
      },
      toggleIncapaz(value) {
        this.form.fields.incapazSim = ''
        this.form.fields.incapazNao = ''
        if(value === 'incapazSim') {
          this.form.fields.incapazSim = true
        } else if (value === 'incapazNao') {
          this.form.fields.incapazNao = true
        }
        this.updateForm()
        this.updateMedicalRecord(this.form)
      },
      onSelect(event, option) {
        if(option === 'medicamentoOpcao1'){
          this.form.fields.posologiaOpcao1 = event.max_dosage
          this.updateForm('medicamentoOpcao1', event.medicine_name)
        }
        if(option === 'medicamentoOpcao2'){
          this.form.fields.posologiaOpcao2 = event.max_dosage
          this.updateForm('medicamentoOpcao2', event.medicine_name)
        }
        if(option === 'medicamentoOpcao3'){
          this.form.fields.posologiaOpcao3 = event.max_dosage
          this.updateForm('medicamentoOpcao3', event.medicine_name)
        }
        if(option === 'medicamentoOpcao4'){
          this.form.fields.posologiaOpcao4 = event.max_dosage
          this.updateForm('medicamentoOpcao4', event.medicine_name)
        }
        this.updateMedicalRecord(this.form)
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--neutral-300);
    border-left: 1px solid var(--neutral-300);
    border-right: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 15px 0 16px 0;
  
    .custom-input {
      flex: 1;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;
     
      &.min-h {
        min-width: 200px;
      }

      &.min-h-o {
        min-width: 140px;
      }
  
      &.h-38 {
        height: 38px;
      }
  
      .form-control {
        border-radius: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 1 solid var(--neutral-300) !important;

        justify-items: center;
        align-items: center;
        text-align: center;

        &.input {
          justify-items: center;
          align-items: center;
          text-align: center;
        }

      }
  
      .border-text {
        border-bottom: 0px !important;
        border-radius: 0px 0px 10px 0px !important;
      }
  
      &.radius-bootom-d {
        border-radius: 0 0 8px 0;
      }
      &.radius-bootom-e {
        border-radius: 0 0 0 8px;
      }
  
      &.no-bb {
        border-bottom: 0;
      }
  
      &.no-br {
        border-right: 0;
      }
  
      &.with-br {
        border-right: 1px solid var(--neutral-300);
      }
  
      &.bbr {
        border-radius: 0 0 8px 0 !important;
      }
  
      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);
  
        .form-control {
          border: 0 !important;
          border-radius: 0 !important;
  
          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }
  
          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }
  
        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;
  
          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
  
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }
  
        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }
  
        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }
  
  .eye-box-container {
    background-color: var(--neutral-100);
    align-items: center;
  
    .eyeValueChange {
      visibility: hidden;
    }
  
    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }
  
  .radius-top-d  {
    border-radius: 0 8px 0 0;
  }
  .radius-top-e {
      border-radius: 8px 0 0 0;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500);
  }
  
  .laterality-container {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #0C1D59;
      }
      .laterality-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-left: 8px;
        .laterality-input {
          display: flex;
          flex-direction: row;
          gap: 24px;
          .laterality-option {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--type-active);
            input {
              height: 20px;
              width: 20px;
            }
          }
        }
        .btn {
          width: auto !important;
          height: 38px;
          margin-bottom: 10px;
        }
      }
    }
  
  .blue {
    color: var(--blue-500);
    display: inline-block;
  }

  .text-area {
    flex: 1;
    display: flex;
    background-color: var(--neutral-100);
    justify-items: center;
    align-items: center;

    line-height: 55px;
    margin-left: 16px;
    font-weight: 400;
    font-size: 16px;
    color: var(--type-active);

    &.min-h {
      min-width: 200px;
    }

    &.min-h-o {
      min-width: 140px;
    }

    &.disabled {
      background-color: #e9ecef !important;
    }

    .form-control {
      border: 0 !important;
      justify-items: center;
      align-items: center;
      text-align: center;

      &.input {
        justify-items: center;
        align-items: center;
        text-align: center;
      }
      
    }

    &.type-2 {
      width: 100%;
      position: relative;
      margin-left: 0;
      text-align: center;
      justify-items: center;
      background-color: var(--neutral-000);

      span {
        width: 100%;
        position: absolute;
        top: -19px;
        left: 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: var(--type-active);
      }
    }

    &.borderB {
      border-bottom: 1px solid var(--neutral-300);
    }
  }
  .autoFillBtnLabel {
    margin: 0px 0px 10px 0px;
    line-height: 30px;
    font-weight: 600;
    color: var(--dark-blue);
  }
  .check-disable {
    background-color: var(--neutral-100) !important;
  }
</style>
  